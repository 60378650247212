import { List } from "@mui/material";
import { groupBy } from "../../../../../views/common/linq";
import React, { ReactElement } from "react";
import { AddShiftData, AddShiftPopoverProps } from "../../dialogs/AddShiftPopover/AddShiftPopover";
import { CostDivisionAccordion, CostDivisionAccordionProps } from "./CostDivisionAccordion";
import { DayTaskTemplateItem } from "./DayTaskTemplateItem";


export type DayTaskTemplateListProps = {
    dayTaskTemplates: AddShiftData["dayTaskTemplates"],
    expandFirstItem?: boolean,
    onAdd: (dayTaskTemplate: AddShiftData["dayTaskTemplates"][0]) => void,
    onDelete?: AddShiftPopoverProps["onTemplateDelete"],
    onAccordionChange?: CostDivisionAccordionProps["onChange"],
    isDisabled?: (dayTaskTemplate: AddShiftData["dayTaskTemplates"][0]) => boolean,
};

export function DayTaskTemplateList(props: DayTaskTemplateListProps): ReactElement {
    const { dayTaskTemplates, onAccordionChange, expandFirstItem, ...rest } = props;

    const costDivisions = groupBy(dayTaskTemplates, dtt => dtt.taskTemplates.sort((a, b) => a.fromSec - b.fromSec)[0].costDivision.name);

    return <List>
        {costDivisions.sort((a, b) => a.key.localeCompare(b.key)).map(function (costDivision, index) {
            const color = costDivision.data[0].taskTemplates.sort((a, b) => a.fromSec - b.fromSec)[0].costDivision.backgroundColor;
            return <CostDivisionAccordion key={costDivision.key} title={costDivision.key} color={color} onChange={onAccordionChange} defaultExpanded={expandFirstItem && index === 0} >
                <List disablePadding>
                    {costDivision.data.sort((a, b) => a.name.localeCompare(b.name)).map(dtt =>
                        <DayTaskTemplateItem key={dtt.ref} dayTaskTemplate={dtt} {...rest} />)}
                </List>
            </CostDivisionAccordion>;
        })}
    </List >;
}