import _ from "lodash";
import {
    Person, Workspace
} from "./workspace";
import { orderByNumber, selectMany, skipWhile, sum } from "tp/views/common/linq";
import { DayTask } from "./dayTask";
import { TaskType as TaskTypeEnum } from "tp/views/common/models/TaskType";
import { FormattedMessage } from "react-intl";
import React from "react";
import { DAY_SECONDS, HOUR_SECONDS, formatTimeDurationDay } from "tp/shared/common/format";
import { TaskForm } from "tp/site-admin/common/messages";
import { Common } from "tp/views/common/messages";

const MINUTE_SECONDS = 60;

const DAY_LIMIT = 5 * MINUTE_SECONDS; // How many seconds must be on today
const MIN_TASK_START = 0; // don't allow task to start yesterday
const MAX_TASK_START = 24 * HOUR_SECONDS - DAY_LIMIT; // don't allow tasks to start tomorrow
const MAX_TASK_END = 2 * 24 * HOUR_SECONDS;
const MIN_TASK_LENGTH = 1 * HOUR_SECONDS;
const MAX_TASK_START_TOMORROW = MAX_TASK_END - MIN_TASK_LENGTH;

export interface TimeInterval {
    fromSec: number;
    untilSec: number;
}

export interface Break extends TimeInterval {
    customBreakPos: boolean;
}

export interface Task extends TimeInterval {
    taskRef: number;
    color?: string;
    textColor?: string;
    backgroundColor?: string;
    hotelRef?: number;
    costDivisionRef?: number;
    costDivisionName?: string;
    taskTypeRef?: TaskTypeEnum;
    taskTemplateRef?: number;
    comment?: string;
    breaks: Break[];
    deleted?: boolean;
    collectiveAgreementRef?: number;
    foodBenefit?: boolean;
    customBreakSec?: number;

    calculatedBreakSec?: number;
}

export interface TaskType {
    taskTypeRef: number;
    taskTypeName: string;
}

export function getDummyTask(fromSec = 0, untilSec = 1): Task {
    return {
        fromSec: fromSec,
        untilSec: untilSec,
        taskRef: null,
        breaks: [],
        taskTypeRef: TaskTypeEnum.Normal
    };
}

export function getSumOfTaskBreaks(task: Task): number {
    return sum(task.breaks, b => b.untilSec - b.fromSec);
}

export function getSumOfTask(task: Task): number {
    return task.untilSec - task.fromSec - getSumOfTaskBreaks(task);
}


const timeRegex = /^(\d{1,2})(:?(\d{2}))?$/;

export function timeStringToSeconds(time: string): number {
    const match = timeRegex.exec(time);

    if (!match) {
        return NaN;
    }

    const [, hours, , minutes] = match;
    const minuteSeconds = 60;

    return (parseInt(hours) * HOUR_SECONDS) + (minutes ? parseInt(minutes) * minuteSeconds : 0);
}


export function timeIntervalString(tasks: Task[]): string {
    return orderByNumber(tasks, t => t.fromSec)
        .map(task => formatTimeDurationDay(task.fromSec) + "-" + formatTimeDurationDay(task.untilSec))
        .join(", ");
}

export interface ToolTipProps {
    task: Task,
    collectiveAgreementName?: string,
    taskTypeName?: string
}

export const TaskTimeToolTip = (props: ToolTipProps): React.ReactElement => {
    return <table style={{ borderSpacing: 0 }}>
        <tbody>
            {getTaskTimeInfo(props.task)}
            {!isTaskNormal(props.task) && getTaskNotNormalInfo(props.task, props.collectiveAgreementName, props.taskTypeName)}
        </tbody>
    </table>;
};

export function getTaskTimeInfo(task: Task): React.ReactElement {
    const totalTime = task.untilSec - task.fromSec;
    const breakTime = task.calculatedBreakSec || 0;
    const taskTime = totalTime - breakTime;

    const getTimeString = (time: number): string => (
        isNaN(time) ? "--:--" : `${formatTimeDurationDay(time)}`
    );

    return <>
        <tr>
            <td style={{ padding: "4px", verticalAlign: "top", whiteSpace: "nowrap" }}><FormattedMessage id="InteractiveTask.TaskLength" /></td>
            <td style={{ padding: "4px" }}>{getTimeString(taskTime)}</td>
        </tr>
        <tr>
            <td style={{ padding: "4px", verticalAlign: "top", whiteSpace: "nowrap" }}><FormattedMessage id="InteractiveTask.Break" /></td>
            <td style={{ padding: "4px" }}>{getTimeString(breakTime)}</td>
        </tr>
    </>;
}

/*
*  If a Task is not "normal" it should sometimes be displayed with italic font.
*  Normality is according to issue #3631
*/
const isTaskNormalConditions = [
    { key: "TaskType", condition: (task): boolean => task.taskTypeRef === TaskTypeEnum.Normal },
    { key: "CustomBreak", condition: (task): boolean => typeof task.customBreakSec !== "number" },
    { key: "FoodBenefit", condition: (task): boolean => typeof task.foodBenefit !== "boolean" },
    { key: "CollectiveAgreement", condition: (task): boolean => !task.collectiveAgreementRef },
    { key: "Comment", condition: (task): boolean => !task.comment },
];

export function isTaskNormal(task: Task): boolean {
    return isTaskNormalConditions.map(e => e.condition(task)).every(e => e);
}

export function getTaskNotNormalInfo(task: Task, collectiveAgreementName?: string, taskTypeName?: string): React.ReactElement {
    const { foodBenefit, comment } = task;

    const conditionAndInfo = [
        {
            conditionKey: "TaskType",
            info: <span>{taskTypeName}</span>
        },
        {
            conditionKey: "CustomBreak",
            info: <FormattedMessage id="InteractiveTask.CustomBreak" />
        },
        {
            conditionKey: "FoodBenefit",
            info: (
                <FormattedMessage {...(foodBenefit ?
                    TaskForm.AddFoodBenefit :
                    TaskForm.NoFoodBenefit)
                } />
            )
        },
        {
            conditionKey: "CollectiveAgreement",
            info: <span>{collectiveAgreementName}</span>
        }
    ];

    let numberOfConditions = 0;

    const taskInfo = conditionAndInfo.map(({ conditionKey, info }) => {
        if (!isTaskNormalConditions.find(e => e.key === conditionKey).condition(task)) {
            numberOfConditions++;
            return <span key={numberOfConditions}>{numberOfConditions !== 1 ? ", " : ""}{info}</span>;
        }
    });

    const commentCondition = { conditionKey: "Comment", info: <span style={{ whiteSpace: "pre-line" }}>{comment}</span> };
    const commentInfo = !isTaskNormalConditions.find(e => e.key === commentCondition.conditionKey).condition(task) &&
        <tr>
            <td style={{ borderTop: "thin solid", padding: "4px", verticalAlign: "top", whiteSpace: "nowrap" }}><FormattedMessage {...Common.Comment} /></td>
            <td style={{ borderTop: "thin solid", padding: "4px" }}>{commentCondition.info}</td>
        </tr>;

    return <>
        {numberOfConditions > 0 &&
            <tr>
                <td style={{ padding: "4px", verticalAlign: "top", whiteSpace: "nowrap" }}><FormattedMessage {...Common.OwnChanges} /></td>
                <td style={{ padding: "4px" }}>{taskInfo}</td>
            </tr>
        }
        {commentInfo}
    </>;
}


export function fromPersonGetAllTasks(person: Person, dayTasks: DayTask[]): Task[] {
    const { todaysTasks } = person;

    const dayTasksTasksOfPerson = _(dayTasks)
        .filter(dt => dt.personRef === person.personRef && !dt.deleted)
        .flatMap(dt => dt.tasks)
        .value();

    // Replace static todaysTasks with tasks that the user can move around
    if (dayTasksTasksOfPerson) {
        return _.unionBy(dayTasksTasksOfPerson, todaysTasks, dt => dt.taskRef)
            .filter(t => !t.deleted);
    }

    return todaysTasks;
}

export function fromDayTaskGetAllTasks(dayTask: DayTask, person: Person, dayTasks: DayTask[]): Task[] {
    let result = [];
    if (!person) {
        result = dayTask.tasks;
    } else {
        result = fromPersonGetAllTasks(person, dayTasks);
    }

    return _(result)
        .filter(t => !t.deleted)
        .sortBy(t => t.fromSec)
        .value();
}

/*
*   Tasks A can contain Tasks that can start (fromSec) yesterday and have untilSec today. (For example person.todaysTasks)
*   Tasks B have earliest fromSec at 0 and can have untilSec til tomorrow.
*/
export function areTasksOverlapping(tasksA: Task[], tasksB: Task[]): boolean {
    let isOverlapping = false;

    for (let i = 0; i < tasksA.length; i++) {
        const taskA = tasksA[i];

        for (let j = 0; j < tasksB.length; j++) {
            const taskB = tasksB[j];

            // Is taskb fromSec or untilSec in interval?
            if ((taskB.fromSec > taskA.fromSec && taskB.fromSec < taskA.untilSec) ||
                (taskB.untilSec > taskA.fromSec && taskB.fromSec < taskA.untilSec)) {
                isOverlapping = true;
                break;
            }
        }

        if (isOverlapping) break;
    }

    return isOverlapping;
}

export function getSnapSecTask(task: Task, snapSec: number): Task {
    const { fromSec, untilSec } = task;

    const snapTheSec = (sec): number => Math.round(sec / snapSec) * snapSec;

    return {
        ...task,
        fromSec: snapTheSec(fromSec),
        untilSec: snapTheSec(untilSec)
    };
}

export function getConnectedTasks(task: Task, dayTask: DayTask): Task[] {
    const sortedTasks = [...dayTask.tasks].sort((t1, t2) => t1.fromSec - t2.fromSec);
    const taskIndex = sortedTasks.findIndex(t => t.taskRef === task.taskRef);

    let startIndex = taskIndex;
    for (let i = taskIndex - 1; i >= 0; i--) {
        if (sortedTasks[i].untilSec < sortedTasks[i + 1].fromSec) break;
        startIndex = i;
    }

    let endIndex = taskIndex;
    for (let i = taskIndex + 1; i < sortedTasks.length; i++) {
        if (sortedTasks[i].fromSec > sortedTasks[i - 1].untilSec) break;
        endIndex = i;
    }

    return sortedTasks.slice(startIndex, endIndex + 1);
}

export function computeMinFromSec(dt: DayTask, oldTask: Task, state: Workspace): number {
    const tasksBefore = _.takeWhile(
        fromDayTaskGetAllTasks(dt, state.persons.find(p => p.personRef === dt.personRef), state.dayTasks),
        t => t.taskRef !== oldTask.taskRef
    );

    if (tasksBefore.length > 0) {
        return Math.max(tasksBefore[tasksBefore.length - 1].untilSec, MIN_TASK_START);
    } else {
        return MIN_TASK_START;
    }
}

export function computeMaxFromSec(oldTask: Task, newTask: Task, minTaskLengthSec?: number): number {
    if (oldTask.fromSec > DAY_SECONDS) {
        return oldTask.fromSec;
    }

    if (oldTask.untilSec === newTask.untilSec) {
        // set min size when moving start
        return Math.min(MAX_TASK_START, oldTask.untilSec - minTaskLengthSec);
    } else {
        return MAX_TASK_START;
    }
}

export function computeMaxUntilSec(dt: DayTask, oldTask: Task, state: Workspace): number {
    const tasksAfter = skipWhile(
        fromDayTaskGetAllTasks(dt, state.persons.find(p => p.personRef === dt.personRef), state.dayTasks),
        (t) => t.taskRef !== oldTask.taskRef
    );

    if (tasksAfter.length > 1) {
        return tasksAfter[1].fromSec;
    } else {
        return MAX_TASK_END;
    }
}

export function computeMinUntilSec(minTaskLengthSec: number, oldTask: Task, newTask: Task): number {
    if (oldTask.fromSec === newTask.fromSec) {
        // set min length when moving end
        return oldTask.fromSec + minTaskLengthSec;
    } else {
        const minTaskStart = DAY_LIMIT - (oldTask.untilSec - oldTask.fromSec);
        return minTaskStart;
    }
}

/* After a task has been resized or a break has been moved, this function is called
 * to ensure that the breaks of a task doesn't end up in a stack.
 * 
 * TODO
 */
export function unstackBreaks(oldTask: Task, newTask: Task): Task {
    return newTask;
}

/* Handles the effects of moving a break. Called on by ensureWitihnBounds.
 * This statement should maybe eventually have
 * its own action in ../actions.ts and ../reducers/workspace.ts.
 * - Breaks should not be able to be resized, only moved.
 * - Assumes minBreakLengthSec = minTaskLengthSec.
 */
export function handleBreakChange(oldTask: Task, newTask: Task): Task {
    // breakIndex: index of the break that has changed
    const breakIndex = newTask.breaks.findIndex((elem, index) => (
        elem !== oldTask.breaks[index]
    )
    );

    const breakLength = newTask.breaks[breakIndex].untilSec - newTask.breaks[breakIndex].fromSec;
    let newFromSec = newTask.breaks[breakIndex].fromSec;
    let newUntilSec = newTask.breaks[breakIndex].untilSec;

    // Ensure break doesn't start before task
    if (newFromSec < oldTask.fromSec) {
        newFromSec = oldTask.fromSec;
        newUntilSec = oldTask.fromSec + breakLength;
    }

    // Ensure break doesn't end after task
    if (newUntilSec > oldTask.untilSec) {
        newUntilSec = oldTask.untilSec;
        newFromSec = oldTask.untilSec - breakLength;
    }

    newTask.breaks[breakIndex].fromSec = newFromSec;
    newTask.breaks[breakIndex].untilSec = newUntilSec;
    return unstackBreaks(oldTask, newTask);
}


/* After a task is moved or resized, this function is called to, if need be, move the breaks of that task
 * inside bounds. It assumes that the task is large enough to accommodate all breaks. Called on by
 * ensureWithinBounds.
 */
export function ensureBreaksWithinBounds(oldTask: Task, newTask: Task): Task {
    const isTaskMoving = newTask.fromSec - newTask.untilSec === oldTask.fromSec - oldTask.untilSec;
    /* If task is moving, move breaks with it.
     * Else, make sure all breaks are inside the task.
     * (They may end up stacked though.)
     */
    if (isTaskMoving) {
        const diff = newTask.fromSec - oldTask.fromSec;
        newTask.breaks.forEach((b) => {
            b.fromSec += diff;
            b.untilSec += diff;
        });
    } else {
        newTask.breaks.forEach(b => {
            if (b.fromSec < newTask.fromSec) {
                b.untilSec += newTask.fromSec - b.fromSec;
                b.fromSec += newTask.fromSec - b.fromSec;
            } else if (b.untilSec > newTask.untilSec) {
                b.fromSec += newTask.untilSec - b.untilSec;
                b.untilSec += newTask.untilSec - b.untilSec;
            }
        });
        newTask = unstackBreaks(oldTask, newTask);
    }

    return newTask;
}

// Check to see if a task can be created where the user wants to
export function isTaskWithinBounds(dayTasks: DayTask[], task: Task, isATomorrowClick?: boolean): boolean {
    const { fromSec, untilSec } = task;
    const allTasks = selectMany(dayTasks.filter(dt => dt), dt => dt.tasks)
        .filter(t => !t.deleted);

    const isInsideAnotherTask = (
        allTasks.find(t => fromSec >= t.fromSec && fromSec < t.untilSec) || // Is fromSec inside an already existing task
        allTasks.find(t => untilSec < t.untilSec && untilSec > t.fromSec) // Is untilSec inside an already existing task
    );
    const normalIntervalOk = fromSec >= MIN_TASK_START && fromSec <= MAX_TASK_START;
    const tomorrowIntervalOk = fromSec >= MIN_TASK_START && fromSec <= MAX_TASK_START_TOMORROW;

    if (isATomorrowClick) {
        return tomorrowIntervalOk && !isInsideAnotherTask;
    } else {
        return normalIntervalOk && !isInsideAnotherTask;
    }
}

/* Handles the effects of moving or resizing a task. Does not directly alter breaks,
 * this is done by handleBreakChange and ensureBreaksWithinBounds.
 */
export function ensureTaskWithinBounds(dt: DayTask, oldTask: Task, newTask: Task, state: Workspace): Task {
    if (newTask.breaks.length > 0 && newTask.breaks !== oldTask.breaks) {
        return handleBreakChange(oldTask, newTask);
    }

    const minFromSec = computeMinFromSec(dt, oldTask, state);
    const maxFromSec = computeMaxFromSec(oldTask, newTask);
    const maxUntilSec = computeMaxUntilSec(dt, oldTask, state);

    const isTaskMoving =
        newTask.fromSec - newTask.untilSec === oldTask.fromSec - oldTask.untilSec;

    // Ensure task doesn't start before bound
    if (newTask.fromSec < minFromSec) {
        if (isTaskMoving) {
            newTask.untilSec -= (newTask.fromSec - minFromSec);
        }
        newTask.fromSec = minFromSec;
    }

    // Ensure task doesn't start after bound
    if (newTask.fromSec > maxFromSec) {
        if (isTaskMoving) {
            newTask.untilSec -= (newTask.fromSec - maxFromSec);
        }
        newTask.fromSec = maxFromSec;
    }

    // Ensure task doesn't end after bound
    if (newTask.untilSec > maxUntilSec) {
        if (isTaskMoving) {
            newTask.fromSec -= (newTask.untilSec - maxUntilSec);
        }
        newTask.untilSec = maxUntilSec;
    }

    /* If task is moved, move its breaks with it.
     * Else, if its resized, ensure task length is at least minTaskLengthSec
     */
    if (!isTaskMoving) {
        // Ensure task can fit all breaks
        let totBreakSize = 0;
        newTask.breaks.forEach(b => {
            totBreakSize += b.untilSec - b.fromSec;
        });

        const minUntilSec = computeMinUntilSec(Math.max(MIN_TASK_LENGTH, totBreakSize), oldTask, newTask);
        newTask.untilSec = Math.max(newTask.untilSec, minUntilSec);

        const maxFromSec = computeMaxFromSec(oldTask, newTask, Math.max(MIN_TASK_LENGTH, totBreakSize));
        newTask.fromSec = Math.min(newTask.fromSec, maxFromSec);
    }

    return ensureBreaksWithinBounds(oldTask, newTask);
}

export function ensureAllTasksWithinBounds(dayTask: DayTask, state: Workspace): DayTask {
    const orderedTasks = fromDayTaskGetAllTasks(
        dayTask,
        state.persons.find(p => p.personRef === dayTask.personRef),
        _.unionBy([dayTask], state.dayTasks, dt => dt.dayTaskRef));

    const updatedTasks = orderedTasks
        .reduce((tasks, t) => {
            if (t.fromSec > MAX_TASK_START) {
                const prevTask = tasks[tasks.length - 1];
                const newFromSec = Math.max(prevTask ? prevTask.untilSec : MAX_TASK_START, MAX_TASK_START);

                tasks.push({ ...t, fromSec: newFromSec, untilSec: newFromSec + (t.untilSec - t.fromSec) });
            } else {
                tasks.push(t);
            }

            return tasks;
        }, []);

    return {
        ...dayTask,
        tasks: dayTask.tasks.map(t => updatedTasks.find(ut => ut.taskRef === t.taskRef) || t)
    };
}
