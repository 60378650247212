import React, { CSSProperties, ReactElement, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CheckCircleRounded, Create, Replay } from "tp/shared/common-design/icons";
import { DateTime } from "luxon";
import { Black, Gray2, TPBlue, TPGreen, TPRed, White } from "tp/views/common-design/colors";
import { marginLg, marginMd, marginXs, shortHandMargin, toolbarMargin } from "tp/views/common-design/margins";
import { OnDemandModal } from "tp/views/common-forms/components/OnDemandModal";
import { digitalSigningContractRefCancelPost, initialize as apiInitialize } from "tp/shared/timeplan-api-client";
import { SigningStatusBox } from "./SigningStatusBox";
import { FormattedMessage } from "react-intl";
import { borderRadius, topBorderRadius } from "tp/views/common-design/borders";
import { useDispatch } from "react-redux";
import { INIT, loadSigningLogs, openWizard } from "./actions";
import { DigitalSigningLogState, useDigSigLogSelector } from "./redux";
import { LoadState } from "tp/views/common/models";
import { LoadingClock } from "tp/shared/common/components/loading/LoadingClock";
import { DigitalSigningWizard } from "./signingWizard/DigitalSigningWizard";
import { getUrls } from "tp/views/common/reducers/runtime";
import { Time } from "tp/views/common/messages";

const PREFIX = "DigitalSigningLog";

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`,
    disabled: `${PREFIX}-disabled`,
    startIcon: `${PREFIX}-startIcon`
};

const StyledTableRow = styled(TableRow)({
    [`& .${classes.root}`]: {
        fontSize: "12px",
    },
    [`& .${classes.label}`]: {
        color: TPBlue,
    },
    [`& .${classes.disabled}`]: {
        "& span": {
            color: Gray2
        }
    },
    [`& .${classes.startIcon}`]: {
        margin: shortHandMargin(0, marginXs)
    }
});

export type PriceSpecification = {
    price: number;
    text?: string;
}

export type Pricing = {
    electronicId: PriceSpecification;
    checkbox: PriceSpecification;
}

export type DigitalSigningLogProps = Pick<DigitalSigningLogState, "employeeInfo" | "employerInfo" | "contractRef" | "pricing">;

export enum SigningLogType {
    Neutral = "Neutral",
    Positive = "Positive",
    Negative = "Negative"
}

const intlName = (str: string): string => `DigitalSigningLog.${str}`;

export function DigitalSigningLog(props: DigitalSigningLogProps): ReactElement {
    const { contractRef, employeeInfo, employerInfo, pricing } = props;
    const loadState = useDigSigLogSelector(state => state.digitalSigningLog.loadState);
    const signingStatus = useDigSigLogSelector(state => state.digitalSigningLog.signingStatus);
    const lastLoadedSigningStatus = useRef<DigitalSigningLogState["signingStatus"]>(undefined);

    const dispatch = useDispatch();

    useEffect(() => apiInitialize(getUrls()), []);

    useEffect(() => {
        if (contractRef !== 0) {
            dispatch({ type: INIT, payload: { contractRef, employeeInfo, employerInfo, pricing } });
            dispatch(loadSigningLogs());
        }
    }, [contractRef, employeeInfo, employerInfo, pricing]);

    // This is a bit of a hack to reload the page when signing status changes so WebForms can adapt to the change.
    useEffect(() => {
        if (loadState === LoadState.Idle) {
            if (lastLoadedSigningStatus.current === undefined) {
                lastLoadedSigningStatus.current = signingStatus;
                return;
            }

            if (lastLoadedSigningStatus.current !== signingStatus) {
                window.location.replace(window.location.href);
            }
        }
    }, [loadState, signingStatus]);

    // Person doesn't have a contract yet
    if (contractRef === 0) {
        return null;
    }

    const left = 250 + 600; // MainMenu width + TimeplanDiv width
    const mainStyle: CSSProperties = {
        left: left,
        margin: shortHandMargin(toolbarMargin, 0),
        maxWidth: "35%",
        minWidth: "25%"
    };

    return (
        <>
            <div className="verticalStretchPanel" style={mainStyle}>
                <SigningStatusBox />
                <SigningButtons />
                <Log />
            </div>
            {/* Gets toggled by SigningButtons */}
            <DigitalSigningWizard />
        </>
    );
}

const SmallerButton = Button;

function SigningButtons() {
    const signingStatus = useDigSigLogSelector(state => state.digitalSigningLog.signingStatus);
    const contractRef = useDigSigLogSelector(state => state.digitalSigningLog.contractRef);
    const readonly = useDigSigLogSelector(state => state.digitalSigningLog.readonly);

    const dispatch = useDispatch();

    const handleCancelSigning = async () => {
        await digitalSigningContractRefCancelPost(contractRef);
        dispatch(loadSigningLogs());
    };
    const handleStartSigning = async () => {
        dispatch(openWizard());
    };
    const inProgress = signingStatus === "Sent" || signingStatus === "PartiallySigned";
    const statusToNumSigned = [
        { status: "Sent", numSigned: "0" },
        { status: "PartiallySigned", numSigned: "1" }
    ];

    return (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: marginMd }}>
            <SmallerButton
                onClick={handleStartSigning}
                disabled={inProgress || readonly}
                startIcon={<Create />}
                style={{ marginRight: marginMd }}
                classes={{
                    root: classes.root,
                    disabled: classes.disabled,
                    startIcon: classes.startIcon
                }}>
                <FormattedMessage id={intlName("StartSigning")} />
            </SmallerButton>
            <OnDemandModal
                title={{ id: intlName("RecallSigning") }}
                message={{
                    id: intlName("RecallConfirm"),
                    values: {
                        break: <br />,
                        numSigned: statusToNumSigned.find(e => e.status === signingStatus)?.numSigned ?? ""
                    }
                }}
                handler={handleCancelSigning}
            >
                {({ showConfirmDialog }) =>
                    <SmallerButton
                        disabled={!inProgress || readonly}
                        startIcon={<Replay />}
                        onClick={showConfirmDialog}
                        classes={{
                            root: classes.root,
                            disabled: classes.disabled,
                            startIcon: classes.startIcon
                        }}>
                        <FormattedMessage id={intlName("RecallSigning")} />
                    </SmallerButton>
                }
            </OnDemandModal>
        </div>
    );
}

function Log(): ReactElement {
    const loadState = useDigSigLogSelector(state => state.digitalSigningLog.loadState);
    const logs = useDigSigLogSelector(state => state.digitalSigningLog.logs);

    const headerStyle = {
        height: "20px",
        borderRadius: topBorderRadius(),
        backgroundColor: TPBlue,
        color: White,
        padding: shortHandMargin(marginMd, marginMd + marginLg),
        fontSize: "14px"
    };
    const tableStyle = {
        backgroundColor: White,
        border: `${marginMd}px solid ${White}`,
        borderTop: "none",
        minWidth: "20%"
    };
    const loadStyle = {
        position: "absolute",
        top: "100%",
        width: "100%",
        height: "100%",
        background: White
    } as CSSProperties;
    const headers = [Time.date, { id: intlName("Events") }];

    return <>
        {loadState === LoadState.Slow && <div style={loadStyle}><LoadingClock /></div>}

        <div style={headerStyle}><FormattedMessage id={intlName("LogTitle")} /></div>
        <TableContainer style={{ borderRadius: borderRadius }} >
            <Table size="small" style={tableStyle}>
                <TableHead>
                    <StyledTableRow>
                        <TableCell></TableCell>
                        {headers.map(header =>
                            <TableCell style={{ fontSize: "12px" }} key={header.id}>
                                <b><FormattedMessage {...header} /></b>
                            </TableCell>
                        )}
                        <TableCell />
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {logs.map((log, i) => <LogRow key={i} log={log} />)}
                    {logs.length === 0 && <LogRow log={dummyLog} />}
                </TableBody>
            </Table>
        </TableContainer>
    </>;
}

const dummyLog = { date: null, logEvent: "", type: null };

const DateCell = (props: {date?: DateTime, dateCellStyle: CSSProperties, dateStyle: CSSProperties}) => {
    const { date, dateCellStyle, dateStyle } = props;

    return (
        <TableCell style={dateCellStyle} component="th" scope="row">
            {date &&
                <span>
                    {date.toISODate()}
                    <i style={dateStyle}>{` (${date.toISOTime().slice(0, 5)})`}</i>
                </span>
            }
        </TableCell>
    );
};

function LogRow(props: { log: DigitalSigningLogState["logs"][0] }): ReactElement {
    const { date, logEvent, type } = props.log;

    const rowTypeToColor = {
        [SigningLogType.Neutral]: Black,
        [SigningLogType.Positive]: TPGreen,
        [SigningLogType.Negative]: TPRed
    };

    const cellStyle = {
        fontSize: "12px",
        border: "none",
        padding: shortHandMargin(marginMd, marginLg),
        color: rowTypeToColor[type],
        minWidth: "150px"
    };
    const dateCellStyle = {
        ...cellStyle,
        minWidth: "120px"
    };
    const typeCellStyle = {
        ...cellStyle,
        minWidth: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    };
    const dateStyle = {
        color: rowTypeToColor[type]
    };

    const rowTypeToIcon = {
        [SigningLogType.Neutral]: null,
        [SigningLogType.Positive]: <CheckCircleRounded style={{ color: TPGreen, fontSize: "18px" }} />,
        [SigningLogType.Negative]: null
    };

    return (
        <TableRow style={{ border: "none" }}>
            <TableCell style={typeCellStyle}>
                {rowTypeToIcon[type]}
            </TableCell>
            <DateCell date={date} dateStyle={dateStyle} dateCellStyle={dateCellStyle} />
            <TableCell style={cellStyle}>
                {logEvent}
            </TableCell>
        </TableRow>
    );
}
