import React, { ReactElement, ReactNode } from "react";
import { Box, DialogActions, IconButton, Popover, PopoverProps } from "@mui/material";
import { Close as CloseIcon } from "tp/shared/common-design/icons";
import { TPBlue, White } from "../../../../views/common-design/colors";
import { marginLg, marginSm } from "../../../../views/common-design/margins";

export interface ActionPopoverProps extends Omit<PopoverProps, "onClose"> {
    titleElement: ReactNode;
    actions?: ReactNode;
    children?: ReactElement[] | ReactElement;
    onClose?: () => void;
}

const PopoverTitle = ({ titleElement, onClose }): ReactElement => {
    const titleStyle = {
        display: "flex",
        justifyContent: "space-between",
        padding: `${marginSm}px ${marginLg}px ${marginSm}px ${marginLg}px`,
        backgroundColor: TPBlue,
        color: White
    };

    const closeIconStyle = { padding: 0, color: White };

    return (
        <Box style={titleStyle}>
            {titleElement}
            <IconButton style={closeIconStyle} onClick={onClose} size="large">
                <CloseIcon />
            </IconButton>
        </Box>
    );
};

export function ActionPopover(props: ActionPopoverProps): ReactElement {
    const { actions, titleElement, children, onClose, ...restProps } = props;

    return (
        <Popover
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left"
            }}
            onClose={onClose}
            {...restProps}
        >
            <PopoverTitle titleElement={titleElement} onClose={onClose} />
            {children}
            {actions && <DialogActions>
                {actions}
            </DialogActions>}
        </Popover>
    );
}
