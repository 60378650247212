import React, { ReactElement, useEffect, useRef } from "react";
import {
    AppBar,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Toolbar,
    Typography,
    styled,
} from "@mui/material";
import { Close as CloseIcon, Print as PrintIcon } from "tp/shared/common-design/icons";
import { ChevronLeft, ChevronRight } from "tp/shared/common-design/icons";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useDigSigLogSelector } from "../redux";
import { decrementStep, incrementStep, closeWizard, UPDATE_CONTRACTINPUT } from "../actions";
import { marginMd } from "tp/views/common-design/margins";
import { Common, CommonElements } from "tp/views/common/messages";
import { Gray0, Gray3, TPBlue, TPTurquoise_Dark, White } from "tp/views/common-design/colors";
import { StepViewContract } from "./step1/StepViewContract";
import { StepConfirmation } from "./step3/StepConfirmation";
import { StepSigningChoices } from "./step2/StepSigningChoices";
import { INPUT_CHANGED_MESSAGE_TYPE } from "tp/site-admin/staff-contract/reports/components/FormContextProvider";

export const wizardIntl = (str: string): string => `DigitalSigningWizard.${str}`;

export enum WizardSteps {
    ViewContract = 1,
    SigningChoices = 2,
    Confirmation = 3
}

export function DigitalSigningWizard(): ReactElement {
    const { isWizardOpen, wizardStep } = useDigSigLogSelector(state => state.digitalSigningLog);
    const dispatch = useDispatch();

    const contractFrame = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.origin === window.location.origin &&
                event.data?.messageType === INPUT_CHANGED_MESSAGE_TYPE) {
                dispatch({
                    type: UPDATE_CONTRACTINPUT,
                    payload: { contractInput: event.data.value }
                });
            }
        };

        window.addEventListener("message", handleMessage, false);
        return () => window.removeEventListener("message", handleMessage);
    }, []);

    const handleClose = () => dispatch(closeWizard());
    const printReport = () => {
        if (contractFrame.current.contentWindow) {
            contractFrame.current.contentWindow.postMessage({ showSignatures: true }, window.location.origin);
            setTimeout(() => {
                contractFrame.current.contentWindow.print();
                contractFrame.current.contentWindow.postMessage({ showSignatures: false }, window.location.origin);
            });
        }
    };

    return (
        <Dialog
            open={isWizardOpen}
            fullWidth={true}
            maxWidth="md"
            PaperProps={{ style: { height: "100%" } }} // This is the most important height setting!
        >
            <AppBar position="relative">
                <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
                    <SignProcessTitle />
                    <span>
                        {wizardStep === WizardSteps.ViewContract &&
                            <Button color="inherit" onClick={printReport} startIcon={<PrintIcon />}>
                                <FormattedMessage {...Common.print} />
                            </Button>
                        }
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </span>
                </Toolbar>
            </AppBar>
            <DialogContent style={{ backgroundColor: Gray0, overflowY: wizardStep === WizardSteps.ViewContract ? "hidden" : "auto" }}>
                {wizardStep === WizardSteps.ViewContract && <StepViewContract contractFrame={contractFrame} />}
                {wizardStep === WizardSteps.SigningChoices && <StepSigningChoices />}
                {wizardStep === WizardSteps.Confirmation && <StepConfirmation />}
            </DialogContent>
        </Dialog>
    );
}

const BrowseButton = styled(Button)({
    backgroundColor: TPTurquoise_Dark,
    color: White,
    padding: "0.3rem",
    borderRadius: "0.25rem",
    "&:hover": {
        backgroundColor: TPTurquoise_Dark
    },
    "& .MuiButton-startIcon": {
        marginRight: 0,
    },
    "& .MuiButton-endIcon": {
        marginLeft: 0
    },
    "&.Mui-disabled": {
        backgroundColor: TPBlue,
        "& span": {
            color: Gray3
        }
    },
});

function SignProcessTitle() {
    const { wizardStep } = useDigSigLogSelector(state => state.digitalSigningLog);
    const dispatch = useDispatch();

    const handleNextClicked = () => dispatch(incrementStep());

    const wrapperStyle = {
        display: "flex",
        alignItems: "center"
    };

    const isDisabled = (backButton: boolean) => backButton ?
        wizardStep !== WizardSteps.SigningChoices :
        wizardStep !== WizardSteps.ViewContract;

    return (
        <div style={wrapperStyle}>
            <BrowseButton
                style={{ marginRight: marginMd }}
                disabled={isDisabled(true)}
                startIcon={<ChevronLeft />}
                onClick={() => dispatch(decrementStep())}
            >
                {CommonElements.back}
            </BrowseButton>
            <Typography variant="h6">
                <FormattedMessage id={wizardIntl("SigningProcess")} values={{ step: `${wizardStep}` }} />
            </Typography>
            <BrowseButton
                style={{ marginLeft: marginMd }}
                disabled={isDisabled(false)}
                endIcon={<ChevronRight />}
                onClick={handleNextClicked}
            >
                {CommonElements.next}
            </BrowseButton>
        </div>
    );
}
