import React, { ReactElement } from "react";
import { CancelButton, OkButton } from "../../../../views/common/components/buttons/ActionButtons";
import { ActionPopover, ActionPopoverProps } from "./ActionPopover";

export interface SimplePopoverProps extends ActionPopoverProps {
    onOk: () => void;
}

export function SimplePopover(props: SimplePopoverProps): ReactElement {
    const {
        onOk,
        onClose,
        ...restProps } = props;

    const actions = [
        <OkButton key="ok" onClick={onOk} />,
        <CancelButton key="cancel" onClick={onClose} />
    ];

    return <ActionPopover actions={actions} onClose={onClose} {...restProps} />;
}
