import _ from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { DAY_SECONDS, formatTimeDurationDay } from "../../../../shared/common/format";
import { marginSm } from "../../../../views/common-design/margins";
import { timeStringToSeconds } from "tp/site-admin/dayoverview/models/task";
import { TimeInput as Messages } from "../../messages";
import { TimeTextField } from "../../../../views/common/components/inputs/TimeTextField";

export type TaskTime = { fromSec: number, untilSec: number, isCurrentDay: boolean };

export interface TaskTimeInputProps {
    fromSec: number;
    untilSec: number;
    snapSec: number;
    minTaskTimeSec?: number;
    onFromSecChange: (fromSec: number) => void;
    onUntilSecChange: (untilSec: number) => void;
    otherTaskTimes?: TaskTime[];
    disabled?: boolean;
}

type ErrorKind = keyof typeof errors;

const errors = {
    overlap: <FormattedMessage {...Messages.Overlap} />,
    invalidTime: <FormattedMessage {...Messages.InvalidTime} />,
    invalidLength: <FormattedMessage {...Messages.InvalidLength} />
};

const NextDayMessage = () => <FormattedMessage {...Messages.NextDay} />;

function getLastUntilTime(taskTimes: TaskTime[]): number {
    if (taskTimes.length) {
        return _(taskTimes)
            .orderBy(t => t.fromSec)
            .dropWhile(t => !t.isCurrentDay)
            .takeWhile(t => t.isCurrentDay)
            .last()
            ?.untilSec ?? NaN;
    }

    return NaN;
}

export function TaskTimeInput(props: TaskTimeInputProps): ReactElement {
    const { fromSec, untilSec, snapSec, onFromSecChange, onUntilSecChange, disabled, otherTaskTimes = [], minTaskTimeSec = snapSec } = props;

    const [from, setFrom] = useState(isNaN(fromSec) ? "" : formatTimeDurationDay(fromSec));
    const [fromError, setFromError] = useState<ErrorKind>();

    const [until, setUntil] = useState(isNaN(untilSec) ? "" : formatTimeDurationDay(untilSec));
    const [untilError, setUntilError] = useState<ErrorKind>();

    const startsNextDay = fromSec > DAY_SECONDS;
    const endsNextDay = untilSec > DAY_SECONDS;

    useEffect(() => {
        let fromError: ErrorKind = null;
        let untilError: ErrorKind = null;

        let fromSec = timeStringToSeconds(from);
        if (startsNextDay && fromSec < DAY_SECONDS) {
            fromSec += DAY_SECONDS;
        }
        if (snapSec > 0) {
            fromSec = Math.round(fromSec / snapSec) * snapSec;
        }

        let validFromSec = !isNaN(fromSec);

        if (validFromSec && fromSec > DAY_SECONDS && getLastUntilTime(otherTaskTimes) !== fromSec) {
            validFromSec = false;
            fromError = "invalidTime";
        }

        if (validFromSec && otherTaskTimes.some(t => t.fromSec <= fromSec && t.untilSec > fromSec)) {
            validFromSec = false;
            fromError = "overlap";
        }

        let untilSec = timeStringToSeconds(until);
        if (validFromSec && untilSec <= fromSec) {
            untilSec += DAY_SECONDS;
        }
        if (snapSec > 0) {
            untilSec = Math.round(untilSec / snapSec) * snapSec;
        }

        let validUntilSec = !isNaN(untilSec);

        if (validUntilSec && untilSec > 2 * DAY_SECONDS) {
            validUntilSec = false;
            untilError = "invalidTime";
        }

        if (validUntilSec && otherTaskTimes.some(t => t.fromSec < untilSec && t.untilSec >= untilSec)) {
            validUntilSec = false;
            untilError = "overlap";
        }

        if (validFromSec && validUntilSec) {
            const overlap = otherTaskTimes.some(t => t.fromSec < untilSec && t.untilSec > fromSec);

            if (overlap) {
                validFromSec = validUntilSec = false;
                fromError = untilError = "overlap";
            }
        }

        if (validFromSec && validUntilSec && untilSec - fromSec < minTaskTimeSec) {
            validFromSec = validUntilSec = false;
            fromError = untilError = "invalidLength";
        }

        setFromError(fromError);
        setUntilError(untilError);

        onFromSecChange(validFromSec ? fromSec : NaN);
        onUntilSecChange(validUntilSec ? untilSec : NaN);
    }, [from, until, snapSec, minTaskTimeSec, onFromSecChange, onUntilSecChange, otherTaskTimes, startsNextDay]);

    const handleStartBlur = () => {
        if (!isNaN(fromSec)) {
            setFrom(formatTimeDurationDay(fromSec));
        }
    };

    const handleEndBlur = () => {
        if (!isNaN(untilSec)) {
            setUntil(formatTimeDurationDay(untilSec));
        }
    };

    return (
        <>
            <TimeTextField
                label={<FormattedMessage {...Messages.Starttime} />}
                onChange={e => setFrom(e.target.value)}
                value={from}
                style={{ marginRight: marginSm }}
                onBlur={handleStartBlur}
                error={!!(from && isNaN(fromSec))}
                helperText={errors[fromError] || (startsNextDay && <NextDayMessage />) || " "}
                disabled={disabled}
            />
            <TimeTextField
                label={<FormattedMessage {...Messages.Endtime} />}
                onChange={e => setUntil(e.target.value)}
                value={until}
                onBlur={handleEndBlur}
                error={!!(until && isNaN(untilSec))}
                helperText={errors[untilError] || (endsNextDay && <NextDayMessage />) || " "}
                disabled={disabled}
            />
        </>);
}