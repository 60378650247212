import React, { useCallback } from "react";
import { Gray3, TPRed } from "../../../../../views/common-design/colors";
import { ArrowDropDown } from "tp/shared/common-design/icons";
import { GridCellModes, GridRenderCellParams, useGridApiContext, useGridApiEventHandler } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { CellValueType } from "../models/RowType";

interface DisplayInnerProps {
    value: string;
}

function DisplayValidString(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <div className="MuiDataGrid-cellContent">
            {value}
        </div>
    );
}

function DisplayInvalidString(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <span className="MuiDataGrid-cellContent" style={{ color: TPRed }}>
            {value}
        </span>
    );
}

function DisplayPlaceholderString(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <i className="MuiDataGrid-cellContent" style={{ color: Gray3, paddingRight: "3px" }}>
            {value}
        </i>
    );
}

function DisplayDropdown(props: { children: JSX.Element }) {
    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}

            sx={{
                "& > .hide-overflow": {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }
            }}
        >
            <span className="hide-overflow" style={{ flexGrow: 1 }}>
                {props.children}
            </span>
            <ArrowDropDown sx={{
                color: "rgba(0, 0, 0, 0.54)",
                transform: "translateX(3px)",
            }} />
        </Box>
    );
}

function DisplayValidDropdown(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <DisplayDropdown>
            <span>
                {value}
            </span>
        </DisplayDropdown>
    );
}

function DisplayInvalidDropdown(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <DisplayDropdown>
            <span style={{ color: TPRed }}>
                {value}
            </span>
        </DisplayDropdown>
    );
}

function DisplayPlaceholderDropdown(props: DisplayInnerProps): React.ReactElement {
    const { value } = props;
    return (
        <DisplayDropdown>
            <i style={{ color: Gray3 }}>
                {value}
            </i>
        </DisplayDropdown>
    );
}

interface DisplayValueProps {
    displayValid: (props: DisplayInnerProps) => React.ReactElement;
    displayInvalid: (props: DisplayInnerProps) => React.ReactElement;
    displayPlaceholder: (props: DisplayInnerProps) => React.ReactElement;
}

function DisplayValue(props: DisplayValueProps & GridRenderCellParams & CustomViewCellProps): React.ReactElement {
    const { value, formattedValue, placeholder, validator,
        displayValid: DisplayValid,
        displayInvalid: DisplayInvalid,
        displayPlaceholder: DisplayPlaceholder } = props;

    const valid = validator(value);

    if (value === undefined) {
        return <DisplayPlaceholder value={placeholder} />;
    }
    else {
        if (valid) {
            return <DisplayValid value={formattedValue ?? value} />;
        } else {
            return <DisplayInvalid value={formattedValue ?? value} />;
        }
    }
}

interface CustomViewCellProps {
    placeholder: string;
    validator: (value: CellValueType) => boolean;
}

function CustomViewCell(props: CustomViewCellProps & GridRenderCellParams): React.ReactElement {
    const { id, colDef, field } = props;

    const apiRef = useGridApiContext();

    const handleClickAndFocus = useCallback((params) => {
        if (params.id !== id || params.field !== field) return;

        if (params.cellMode === GridCellModes.View) {
            apiRef.current.startCellEditMode({ id: params.id, field: params.field });
        }
    }, []);

    useGridApiEventHandler(apiRef, "cellClick", handleClickAndFocus);
    useGridApiEventHandler(apiRef, "cellFocusIn", handleClickAndFocus);

    switch (colDef.type) {
        case "singleSelect":
            return <DisplayValue {...props} displayValid={DisplayValidDropdown} displayInvalid={DisplayInvalidDropdown} displayPlaceholder={DisplayPlaceholderDropdown} />;
        default:
            return <DisplayValue {...props} displayValid={DisplayValidString} displayInvalid={DisplayInvalidString} displayPlaceholder={DisplayPlaceholderString} />;
    }
}

export function renderCustomViewCell(placeholder: string, validator: (value: CellValueType) => boolean): ((params: GridRenderCellParams) => React.ReactNode) {
    // eslint-disable-next-line react/display-name
    return (params) =>
        <CustomViewCell
            validator={validator}
            placeholder={placeholder}
            {...params}
        />;
}