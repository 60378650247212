import { Box, Stack, IconButton, styled, Typography } from "@mui/material";
import { Close as CloseIcon } from "tp/shared/common-design/icons";
import React from "react";
import { White } from "tp/views/common-design/colors";

const StyledStack = styled(Stack)(
    ({ theme }) => {
        return {
            backgroundColor: theme.palette.primary.main,
            color: White,
            padding: "1em 1em 1em 3em",
        };
    }
);

interface WizardStepWrapperProps {
    title: React.ReactElement;
    isActive: boolean;
    children?: React.ReactNode;
    onClose: () => void;
}

export function WizardStepWrapper(props: WizardStepWrapperProps): React.ReactElement {
    const { title, isActive, children, onClose } = props;

    return isActive && (
        <Box
            style={{
                overflowY: "hidden",
                maxHeight: "100%",
                height: "auto",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <StyledStack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h2" >{title}</Typography>
                <IconButton
                    aria-label="close"
                    sx={{ color: "inherit" }}
                    onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </StyledStack>
            <Box
                style={{
                    padding: "2em 4em",
                    overflowY: "auto",
                    height: "auto"
                }}
            >
                {children}
            </Box>
        </Box>
    );
}
