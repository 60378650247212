import React, { ReactElement } from "react";
import { Button, Icon } from "@mui/material";
import { CommonElements } from "../../messages";

interface NewButtonProps {
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
}

export default function NewButton(props: NewButtonProps): ReactElement {
    const { ...buttonProps } = props;

    const iconStyle = {
        fontSize: 16,
        marginRight: 8
    };
    const buttonStyle = {
        height: "26px",
        paddingLeft: "8px",
        paddingRight: "8px",
        marginTop: "4px",
        borderRadius: "8px"
    };

    return (
        <Button
            variant="contained"
            color="primary"
            {...buttonProps}
            style={buttonStyle}
        >
            <Icon style={iconStyle} className="fas fa-plus-circle" />
            {CommonElements.new}
        </Button>
    );
}
