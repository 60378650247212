import { defineMessages } from "react-intl";

export const AddPersonsMessages = defineMessages({
    StaffTableStepTitle: {
        id: "AddPersons.StaffTableStepTitle",
        defaultMessage: "Add new staff",
    },
    SubmitError: {
        id: "AddPersons.SubmitError",
        defaultMessage: "Something went wrong while submitting. Please contact support in case it happens again"
    }
} as const);