import Button, { ButtonProps } from "@mui/material/Button";
import React, { ReactElement } from "react";
import { styled } from "@mui/material/styles";
import {
    TpButtonFontSize,
    TpButtonFontWeight,
    TpButtonLetterSpacing,
} from "../../../common-design/fonts";

const StyledButton = styled(Button)(
    ({ theme }) => ({
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        borderRadius: "20px",
        fontWeight: TpButtonFontWeight,
        letterSpacing: TpButtonLetterSpacing,
        fontSize: TpButtonFontSize,
        "&:hover": {
            border: "2px solid"
        }
    }));

export default function SecondaryButton(props: ButtonProps): ReactElement {
    return (
        <StyledButton
            {...props}
            variant="outlined"
        >
            {props.children}
        </StyledButton>
    );
}
