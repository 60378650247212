import { AnyAction } from "redux";

import { SessionData } from "../models";

export const SET_CONFIG = "SET_CONFIG";
export const UPDATE_SESSION_VARIABLES = "UPDATE_SESSION_VARIABLES";
export const INIT_RUNTIME_STATE = "INIT_STATE";

export type CurrentUser = {
    userName: string;
    fullName: string;
    uniqueId: string;
};

export interface RuntimeState {
    rootUrl: string;

    apiUrl: string;
    apiV1Url: string;
    
    locale: string;
    shortDateFormat: string;
    dateTimeFormat: string;

    currentUser?: CurrentUser;

    scrollbarHeight: number;

    scrollbarWidth: number;

    sessionData?: SessionData;
}

export type TimeplanURLs = { apiUrl: string; rootUrl: string; apiV1Url: string };

// server-side rendering detection
const canUseDOM = (): boolean => (
    !!(
        typeof window !== "undefined" &&
        window.document.body &&
        window.document.createElement
    )
);

export function getUrls(): TimeplanURLs {
    if (!canUseDOM()) {
        return {
            apiUrl: "",
            rootUrl: "",
            apiV1Url: ""
        };
    }

    let base = window.location.protocol + "//" + window.location.hostname;
    let rootUrl = "/";
    let apiUrl = "/api";

    switch (window.location.port) {
        case "55002":
        case "55001":
            apiUrl = base + ":" + window.location.port;
            break;
        case "54000":
            apiUrl = "http://localhost:55000";
            break;
        case "57337":
        case "5733":
            rootUrl = base + ":" + window.location.port + "/";
            apiUrl = "http://localhost:40000/api";
            break;
        default:
            if (window.location.port > "1000") {
                base = base + ":" + 40000;
            }
            rootUrl = base + "/";
            apiUrl = base + "/api";
    }

    return { rootUrl, apiUrl, apiV1Url: apiUrl + "/v1" };
}

function detectScrollbarWidthHeight() {
    if (!canUseDOM()) {
        return {
            scrollbarHeight: 0,
            scrollbarWidth: 0
        };
    }

    const div = document.createElement("div");
    div.style.overflow = "scroll";
    div.style.visibility = "hidden";
    div.style.position = "absolute";
    div.style.width = "100px";
    div.style.height = "100px";
    document.body.appendChild(div);

    return {
        scrollbarWidth: div.offsetWidth - div.clientWidth,
        scrollbarHeight: div.offsetHeight - div.clientHeight
    };
}

const initialState: RuntimeState = {
    locale: "sv",
    shortDateFormat: "yyyy-MM-dd",
    dateTimeFormat: "yyyy-MM-dd HH:mm",
    rootUrl: "",
    apiUrl: "",
    apiV1Url: "",
    scrollbarHeight: 0,
    scrollbarWidth: 0
};

export function runtime(state: RuntimeState = initialState, action: AnyAction): RuntimeState {
    switch (action.type) {
        case INIT_RUNTIME_STATE: {
            const { scrollbarHeight, scrollbarWidth } = detectScrollbarWidthHeight();
            const { rootUrl, apiUrl, apiV1Url } = getUrls();

            return {
                ...state,
                scrollbarHeight,
                scrollbarWidth,
                rootUrl,
                apiUrl,
                apiV1Url,
            };
        }
        case SET_CONFIG: {
            return {
                ...state,
                locale: action.locale,
                currentUser: action.currentUser,
                shortDateFormat: action.shortDateFormat,
                dateTimeFormat: action.dateTimeFormat
            };
        }
        case UPDATE_SESSION_VARIABLES: {
            return {
                ...state,
                sessionData: { ...state.sessionData, ...action.sessionData }
            };
        }
        default:
            return state;
    }
}
