import React, { CSSProperties, ReactElement, SyntheticEvent } from "react";
import { Button, IconButton, Radio, Typography } from "@mui/material";
import { marginMd, marginSm, marginXl, marginXs, shortHandMargin } from "tp/views/common-design/margins";
import { Divider } from "tp/views/common/components/layout/Divider";
import { Gray4, TPTurquoise, TPTurquoise_Dark } from "tp/views/common-design/colors";
import { EmplInfoFields } from "./EmployeeInfoFields";
import bankIdImg from "../../../../resources/bankid.png";
import { ConfirmAndSend } from "./ConfirmAndSend";
import { FormattedMessage } from "react-intl";
import { Common } from "tp/views/common/messages";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { borderRadius, grayBorder } from "tp/views/common-design/borders";
import { Upload } from "tp/views/common-design/icons/Upload";
import { Close } from "tp/shared/common-design/icons";
import { useDispatch } from "react-redux";
import { UPDATE_SIGNINGMETHOD, UPDATE_PDFATTACHMENT } from "../../actions";
import { useDigSigLogSelector } from "../../redux";
import { wizardIntl } from "../DigitalSigningWizard";
import { PriceSpecification } from "../../DigitalSigningLog";
import { Schemas } from "tp/shared/timeplan-api-schema";
import { TypedFormControlLabel, TypedRadioGroup, TypedRadioGroupProps } from "tp/shared/common-design/mui-forms";

export function StepSigningChoices(): ReactElement {

    return (
        <>
            <Section titleId="PersonalData_Title">
                <EmplInfoFields type="employeeInfo" />
                <EmplInfoFields type="employerInfo" />
            </Section>
            <Section titleId="Attachment_Title">
                <UploadFileForm />
            </Section>
            <Section titleId="SigningMethod_Title">
                <ChooseSigningMethod />
            </Section>
            <Section titleId="Confirm_Title" last>
                <ConfirmAndSend />
            </Section>
        </>
    );
}

function Section(props: { titleId: string, last?: boolean, children }) {
    const { titleId, last, children } = props;

    const titleStyle = {
        color: Gray4,
        padding: shortHandMargin(marginXl, 0)
    };

    const centerContent = {
        display: "flex",
        justifyContent: "center",
        marginBottom: marginXl * 2,
    };

    const leftAlign = {
        display: "flex",
        justifyContent: "flex-start",
        width: "28rem", // Width of two textfields + margins
        flexDirection: "column"
    } as CSSProperties;

    return (
        <>
            <div style={centerContent}>
                <div style={leftAlign}>
                    <Typography variant="subtitle2" style={titleStyle}>
                        <FormattedMessage id={wizardIntl(titleId)} />
                    </Typography>
                    {children}
                </div>
            </div>
            {!last && <Divider />}
        </>
    );
}


function UploadFileForm() {
    const dispatch = useDispatch();
    const { pdfAttachment } = useDigSigLogSelector(state => state.digitalSigningLog);

    const handleFileChange = (e: SyntheticEvent<HTMLInputElement>) => {
        const newPdfAttachment = e.currentTarget.files[0];

        if (newPdfAttachment) {
            dispatch({ type: UPDATE_PDFATTACHMENT, payload: { pdfAttachment: newPdfAttachment } });
            e.currentTarget.value = null; // To make input.onChange work
        }
    };

    const handleCancelFile = () => {
        dispatch({ type: UPDATE_PDFATTACHMENT, payload: { pdfAttachment: null } });
    };

    const buttonStyle = {
        border: grayBorder,
        padding: shortHandMargin(marginXs, marginMd),
        borderRadius: borderRadius
    };

    const fileStyle = {
        display: "flex",
        alignItems: "center",
        color: TPTurquoise,
        marginTop: marginSm
    };

    return (
        <div>
            <input
                id="upload-pdf"
                accept="application/pdf"
                type="file"
                name="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
            />
            <label htmlFor="upload-pdf">
                <Button
                    startIcon={<Upload style={{ color: TPTurquoise_Dark }} />}
                    style={buttonStyle}
                    component="span"
                >
                    <FormattedMessage {...Common.upload} />
                </Button>
            </label>
            <br />
            {
                pdfAttachment &&
                <span style={fileStyle}>
                    <AttachmentIcon fontSize="small" />
                    {pdfAttachment.name}
                    <IconButton
                        style={{ padding: 0, marginLeft: marginSm }}
                        onClick={handleCancelFile}
                        size="large">
                        <Close fontSize="small" />
                    </IconButton>
                </span>
            }
        </div>
    );
}

type SigningMethod = Schemas["SendForSigningCommand"]["signingMethod"];

function ChooseSigningMethod() {
    const signingMethod = useDigSigLogSelector(state => state.digitalSigningLog.signingMethod);
    const pricing = useDigSigLogSelector(state => state.digitalSigningLog.pricing);
    const locale = useDigSigLogSelector(state => state.runtime.locale);
    const dispatch = useDispatch();

    const handleChange: TypedRadioGroupProps<SigningMethod>["onChange"] = (_, value) => {
        dispatch({ type: UPDATE_SIGNINGMETHOD, payload: { signingMethod: value } });
    };

    const priceTextStyle = {
        marginLeft: "3rem",
        color: Gray4,
    };

    const priceText = (price: PriceSpecification) => (
        <i style={priceTextStyle}>
            <FormattedMessage id={wizardIntl("PriceText")} values={{
                amount: price.price.toLocaleString(locale, { minimumFractionDigits: 2 }),
                extraText: price.text
            }} />
        </i>
    );

    const imgSize = "4rem";

    return (
        <TypedRadioGroup<SigningMethod> aria-label="bankid" name="bankid" value={signingMethod} onChange={handleChange}>
            <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <TypedFormControlLabel<SigningMethod>
                        value={"ElectronicId"}
                        control={<Radio color="primary" />}
                        label={<FormattedMessage id={wizardIntl("SignWithBankID")} />}
                    />
                    {priceText(pricing.electronicId)}
                </div>
                <img src={bankIdImg} style={{ width: imgSize, height: imgSize, marginLeft: imgSize }} />
            </div>
            <TypedFormControlLabel<SigningMethod>
                value={"Checkbox"}
                control={<Radio color="primary" />}
                label={<FormattedMessage id={wizardIntl("SignWithoutBankID")} />}
            />
            {priceText(pricing.checkbox)}
        </TypedRadioGroup>
    );
}
