import { Button, ButtonProps, Icon } from "@mui/material";
import React, { ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { borderRadius } from "../../../common-design/borders";
import { TPRed } from "../../../common-design/colors";
import { Common } from "../../messages";

export function AddButton(props: ButtonProps): ReactElement {
    const { style, ...buttonProps } = props;

    const buttonStyle = {
        borderRadius: borderRadius * 4,
        padding: "0.5em 1em",
    };

    const activeStyle = {
        backgroundColor: TPRed,
        color: "white"
    };

    const disabledStyle = {
        backgroundColor: TPRed,
        opacity: 0.6,
    };

    const combinedStyle = {
        ...buttonStyle,
        ...(buttonProps.disabled ? disabledStyle : activeStyle),
        ...style
    };

    return (
        <Button
            startIcon={<Icon className="fas fa-plus-circle icon" />}
            style={combinedStyle}
            {...buttonProps}>
            <FormattedMessage {...Common.add} />
        </Button>
    );
}